body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.numero-en-item {
  list-style-type: none;
  padding-left: 0;
}

sttrong {
  font-weight: bold;
  color: #fff !important;
}

.numero-en-item li {
  position: relative;
  counter-increment: item;
  padding-left: 20px;
  /* Espacio para los números */
}

.numero-en-item li:before {
  content: counter(item) ". ";
  /* Añade el número con un punto al final */
  position: absolute;
  left: 0;
}

.lista-con-punto {
  list-style-type: none;
  padding-left: 0;
}

.lista-con-punto li {
  position: relative;
  padding-left: 25px;
  /* Ajusta el espacio para el carácter ">" y el punto */
}

.lista-con-punto li:before {
  content: "\003E";
  /* Carácter ">" */
  position: absolute;
  left: 0;
}

.lista-con-punto {
  list-style-type: disc;
  /* Establece el tipo de marcador */
}

/* Estilo para el texto principal */
.texto-principal {
  font-size: 1em;
  /* Tamaño de la fuente del texto principal */
  margin-bottom: 10px;
  /* Espacio inferior */
}

/* Estilo para la nota en letra pequeña */
.nota {
  font-size: 0.8em;
  /* Tamaño de la letra pequeña */
  color: #666;
  /* Cambia el color del texto de la nota según tu preferencia */
}

.extraNota {
  display: flex;
  justify-content: center;
  align-items: center;
}

.extraNota p {
  width: 70% !important;
  font-size: 0.8em;
  /* Tamaño de la letra pequeña */
  color: #666;
  /* Cambia el color del texto de la nota según tu preferencia */
}

.images {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 50px;
}

.images .structure {
  width: 200px;
  height: 337px;
  margin: 10px;
  color: #fff;
  background-color: #353535;
  text-align: center;
  display: flex;
  gap: 10px;
  flex-direction: column;
  padding: 10px;
  border: 1px solid #353535;
  border-radius: 1rem;
  font-size: 0.9em;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
}

.images .structure img {
  object-fit: contain;
  height: 250px;
}

.apoyo {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 50px;
  gap: 10px;
}

.apoyo .structureApoyo {
  width: 200px;
  height: 200px;
  color: #fff;
  padding: 20px;
  background-color: #fff;
  text-align: center;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #353535;
  border-radius: 1rem;
  font-size: 0.8em;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
}

.apoyo .structureApoyo img {
  object-fit: contain;
  /* height: 250px; */
}


.pricing-section {
  text-align: center;
  margin: 20px 0;
}

.pricing-cards {
  display: flex;
  justify-content: space-around;
  margin: 20px 0;
  flex-wrap: wrap;
  /* Para que las tarjetas se ajusten en una nueva línea si es necesario */
}

.card {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 30%;
  /* Ajustaremos esto en los media queries */
  text-align: center;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
  margin: 10px;
  /* Para agregar algo de espacio entre tarjetas en dispositivos más pequeños */
}

.card-featured {
  background-color: #fff;
  border: 2px solid #ff6347;
  transform: scale(1.05);
}

.principiantes {
  color: rgb(101, 147, 2);
}

.intermedios {
  color: rgb(3, 116, 135);
}

.universitarios {
  color: rgb(229, 170, 17);
}

.card-price {
  font-size: 24px;
  color: #333;
}

.card-description {
  color: #666;
}

.card-features {
  list-style: none;
  padding: 0;
  color: #555;
}

.card-features li {
  margin: 10px 0;
}

.card-button {
  background-color: #ff6347;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
  text-align: center;
}

.card-button:hover {
  background-color: #e5533d;
}

.logoPrice {
  width: 100px;
  margin: 0 auto;
  transform: rotate(45deg);
}

.perfil {
  width: 70%;
}

.items-list {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: nowrap;
  background-color: black;
  padding: 20px;
  overflow-x: auto;
}

.item {
  text-align: center;
  width: 200px;
  padding: 10px;
  color: white;
}

.item-image {
  width: 100%;
  height: 100px;
  object-fit: contain;
  margin-bottom: 10px;
}

.item-text {
  font-size: 16px;
  color: white;
}

/* Media Queries para Responsiveness */

/* Tablets y dispositivos medianos */
@media (max-width: 768px) {
  .card {
    width: 45%;
    /* Ajuste del ancho para tablets */
  }
}

/* Dispositivos móviles */
@media (max-width: 480px) {
  .card {
    width: 100%;
    /* Ancho completo para dispositivos móviles */
  }

  .items-list {
    flex-direction: column;
    /* Para que los elementos se apilen verticalmente */
    align-items: center;
  }

  .item {
    width: 100%;
    /* Ancho completo para elementos en dispositivos móviles */
    margin-bottom: 20px;
    /* Espacio entre elementos */
  }
}

.slider-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.slide {
  float: left;
  width: 100%;
  display: none;
}

.slide.active {
  display: block;
}

.slide img {
  width: 90%;
  height: auto;
}

.prev,
.next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 18px;
}

.prev {
  left: 0;
}

.next {
  right: 0;
}