


$font-weight-heading: var(--font-weight-heading);
$font-weight-body: var(--font-weight-body);

//-------------------- Font Families --------------------//
$body-font: var(--body-font);
$heading-font: var(--heading-font);

$font-code: var(--font-code);
$font-pre: var(--font-pre);


//-------------------- Font Sizes --------------------//

$font-size-base: var(--font-size-base);
$font-size-h1: var(--font-size-h1);
$font-size-h2: var(--font-size-h2);
$font-size-h3: var(--font-size-h3);
$font-size-h4: var(--font-size-h4);
$font-size-h5: var(--font-size-h5);
$font-size-h6: var(--font-size-h6);
$menu-size: var(--menu-size);


//-------------------- Line Height Variables --------------------//

$line-height-base: var(--line-height-base);
$line-height-content: var(--line-height-content);


