@use "sass:math";
@mixin keyframes($name) {

  @-webkit-keyframes #{$name}{
    @content
  }

  @-moz-keyframes #{$name}{
    @content
  }

  @keyframes #{$name}{
    @content
  }

}

@mixin transition-delay($delay: 1ms , $var : var(--char-index) , $astek : 30ms) {
  transition-delay: calc(30ms * #{$var} + #{$delay});
}

@mixin overlays($count: 0) {
  [data-overlay]:before {
    content: '';
    position: absolute;
    background: #000;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }

  @for $i from 0 through $count {
    [data-overlay="#{$i}"]:before {

      opacity: math.div($i , 10);
    }
  }
}



@mixin MarginPadding($count , $plus , $class , $type , $c : px) {

  @for $i from 0 through $count {
    #{$class}-#{$i * $plus} {
      #{$type} : #{$i * $plus}#{$c};
    }
  }
}


//-------------------- Vertical Middle --------------------//

@mixin middle($horizontal: true, $vertical: true) {
  position: absolute;

  @if ($horizontal and $vertical) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } @else if ($horizontal) {
    left: 50%;
    transform: translate(-50%, 0);
  } @else if ($vertical) {
    top: 50%;
    transform: translate(0, -50%);
  }
}


@mixin helperClass($from , $to , $plus , $class , $type , $c : px) {

  @for $i from 0 through $to {
    #{$class}-#{$i * $plus} {
      #{$type} : #{$i * $plus}#{$c};
    }
  }

}
