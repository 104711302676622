button,
input,
select,
optgroup,
textarea {
  text-rendering: optimizeLegibility;
}

@import "headings";

@import "copy";
