// Backgrounds & Text & Links & Borders

//------> Dark Color
$bg-color: var(--bg-color);
$assistant-color: var(--assistant-color);
$body-color: var(--font-color);
$heading-color: var(--heading-color);
$border-color: var(--border-color);





